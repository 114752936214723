import * as React from 'react';
import './home-clients.scss';
import LogoClient from '../clients/logo-client';

const NEWS_LIST = [
  '+ de 100 formations Angular',
  // '1er rhinocéros RFID',
  // 'TODO : autre news',
  // 'TODO : encore une news',
];

interface NewsWithKey {
  key: number;
  label: string;
}

class HomeClientsNews extends React.Component {

  private interval;
  state: {news: NewsWithKey[]};

  private get news(): NewsWithKey[] {
    return this.state.news;
  }
  private set news(news: NewsWithKey[]) {
    this.setState({news});
  }

  constructor(props) {
    super(props);
    const newsWithKey = NEWS_LIST.map((label, index) => {
      return {key: index, label};
    });
    this.state = {
      news: [...newsWithKey]
    };
  }

  componentDidMount() {
    if (this.news.length > 2) {
      this.interval = setInterval(
        () => this.tick(),
        3000
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick() {
    const rotatedNews = [...this.state['news'].slice(1), this.state['news'][0]];
    this.setState({
      news: rotatedNews
    });
  }

  render() {
    return (
      <section className="clients-news">
        {
          this.state['news'].map(news => {
            return <article key={news.key}>{news.label}</article>;
          })
        }
      </section>
    );
  }
}

const HomeClients = () => (
  <section className="clients">
    <h1>Nos clients</h1>
    <div className="clients-block-shadow">
      <section className="clients-logos">
        <LogoClient num='14'/>
        <LogoClient num='11'/>
        <LogoClient num='16'/>
        <LogoClient num='12'/>
        <LogoClient num='13'/>
        <LogoClient num='15'/>
        <LogoClient num='08'/>
        <LogoClient num='09'/>
        <LogoClient num='10'/>
      </section>
      <HomeClientsNews/>
    </div>
  </section>
);

export default HomeClients;

