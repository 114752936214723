import * as React from 'react';
import './home-vision.scss';
import PhotoThierryChatel from '../global/photo-thierry-chatel';
import PhotoJeremieFrancois from '../global/photo-jeremie-francois';

const HomeVision = () => (
  <div className="vision-equipe-flex">
    <div className="vision-background">
      <article className="vision">
        <h1>Notre vision</h1>
        <p>Les projets audacieux sont des défis intéressants.</p>
        <p>Une vision globale, du recul, une forte expertise technologique et beaucoup de pragmatisme : c’est ce
          qu’il faut pour faire des choix éclairés et surmonter les obstacles techniques.</p>
      </article>

      <article className="qui">
        <h1>Qui sommes-nous ?</h1>
        <p>Obi2b propose de l’expertise technique pour vos projets informatiques.</p>
        <p>Nous nous spécialisons dans le conseil technique, la formation et l’appui aux équipes en place, l'aide aux entrepreneurs,
          principalement dans des situations innovantes, imprévues, ou technologiquement complexes.</p>
      </article>
    </div>

    <section className="equipe">
      <article>
        <PhotoThierryChatel/>
        <h3>Thierry Chatel</h3>
        <p>Architecte logiciel</p>
      </article>
    </section>
  </div>
);

export default HomeVision;

