import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const LogoClient = (props: {num: string}) => {
  const fileName = 'logo-client-' + props.num;
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"}, 
          relativeDirectory: {eq: "clients"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  const nodes = data.images.edges.map(edge => edge.node);
  const node = nodes.find(node => node.name === fileName);
  return (
    <div className="logo-client">
      <Img fluid={node.childImageSharp.fluid} />
    </div>
  );
};

export default LogoClient;
