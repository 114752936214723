import * as React from 'react';
import LogoText from '../global/logo-text';
import './home-solutions.scss';
import iconSolutions from '../../images/home/picto-gris-solutions.png'; // Tell Webpack this JS file uses this image

const HomeSolutions = () => (
  <div className="solutions-background">
    <section className="solutions">
      <header className="solutions-header">
        <div className="section-header-width">
          <div className="icon-solutions">
            <img src={iconSolutions} alt="Obi2b solutions icon" />
          </div>
          <div className="section-header-text">
            <h1><LogoText></LogoText> Solutions</h1>
            <p>Des experts seniors pour vos défis technologiques — pas de commerciaux, ni de débutants.</p>
          </div>
        </div>
      </header>
    </section>

    <section className="solutions">
      <div className="section-flex">
        <section className="solution1">
          <header>
            <h2>1</h2>
            <h3>Étude de projet</h3>
          </header>
          <p className="strong">Avant le projet</p>
          <p>Faisabilité, choix techniques</p>
          <p>Levée de verrou technologique</p>
          <p>Prototypage</p>
          <p>Aide au recrutement</p>
        </section>

        <section className="solution2">
          <header>
            <h2>2</h2>
            <h3>Formation</h3>
          </header>
          <p className="strong">Début du projet</p>
          <p>Formation de l’équipe</p>
          <p>Mise en œuvre de l’architecture technique</p>
          <p>Accompagnement du démarrage</p>
        </section>

        <section className="solution3">
          <header>
            <h2>3</h2>
            <h3>Accompagnement</h3>
          </header>
          <p className="strong">Tout au long du projet</p>
          <p>Support à l’équipe sur les difficultés techniques rencontrées</p>
          <p>Suivi et revue de la réalisation</p>
          <p>Conseil technique sur les alternatives en cours de projet</p>
        </section>
      </div>
    </section>
  </div>
);

export default HomeSolutions;

