import * as React from 'react';
import './home-menu-row.scss';
import logoCarre from '../../images/global/logo-obi2b-carre.png'; // Tell Webpack this JS file uses this image
import { Link } from 'gatsby';

const HomeMenuRow = () => (
  <div className="menu-row">
      <img src={logoCarre} alt="Obi2b small logo"/>
      <nav >
          <Link to="/">Accueil</Link>
          <Link to="/solutions/">Solutions</Link>
          <Link to="/formation/">Formations</Link>
          <Link to="/contact/">Contact</Link>
          <Link to="/blog/">Blog</Link>
      </nav>
  </div>
);

export default HomeMenuRow;

