import * as React from 'react';
import './home-carousel.scss';
import { graphql, useStaticQuery } from 'gatsby'; // Tell Webpack this JS file uses this image
import Img from 'gatsby-image';

const slideShowInterval = 7000; // ms

interface Photo {
  id: string;
  alt: string;
  legend: string;
  image: {
    childImageSharp: {
      fluid: any;
    };
  };
}

class HomeCarousel extends React.Component<{photos: Photo[]}> {
  state: {index: number};
  photos: Photo[];
  private interval;

  get index(): number {
    return this.state.index;
  }
  set index(index: number) {
    this.setState({index});
  }

  get currentPhoto(): Photo {
    return this.photos[this.index];
  }

  constructor(props: {photos: Photo[]}) {
    super(props);
    this.photos = props.photos;
    const index = Math.floor(Math.random() * this.photos.length);
    this.state = {index}; // Cannot call setState (via setIndex) to initialize state.
  }

  next() {
    if (this.index < this.photos.length - 1) {
      this.index++;
    } else {
      this.index = 0;
    }
    this.resetInterval();
  }

  previous() {
    if (this.index > 0) {
      this.index--;
    } else {
      this.index = this.photos.length - 1;
    }
    this.resetInterval();
  }

  componentDidMount() {
    this.interval = setInterval(() => this.next(), slideShowInterval);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  private resetInterval() {
    clearInterval(this.interval);
    this.interval = setInterval(() => this.next(), slideShowInterval);
  }

  render() {
    return (
      <section className="carousel">

        <div className="photo">
          <Img
            fluid={this.currentPhoto.image.childImageSharp.fluid}
            key={this.currentPhoto.id}
            alt={this.currentPhoto.alt}
          />
          <nav>
            <a className="previous" onClick={() => this.previous()}>&lt;</a>
            <a className="next" onClick={() => this.next()}>&gt;</a>
          </nav>
        </div>
        <div className="legend">{this.currentPhoto.legend}</div>

      </section>
    );
  }
}

// export default HomeCarousel;
export default () => {
  const data = useStaticQuery(staticQuery);
  const photos = data.allHomePhotosYaml.edges.map(edge => edge.node);
  return (
    <HomeCarousel photos={photos}></HomeCarousel>
  );
}

export const staticQuery = graphql`
  query {
    allHomePhotosYaml(
      sort: { order: ASC, fields: [id] }
    ) {
      edges {
        node {
          id
          image {
            childImageSharp {
              fluid(maxWidth: 3000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          legend
        }
      }
    }
  }
`;
