import * as React from 'react';
import HomeSolutions from '../components/home/home-solutions';
import HomeHeader from '../components/home/home-header';
import HomeVision from '../components/home/home-vision';
import HomeContact from '../components/home/home-contact';
import HomeClients from '../components/home/home-clients';
import HomeFooter from '../components/home/home-footer';
import HomeMenuRow from '../components/home/home-menu-row';
import HomeCarousel from '../components/home/home-carousel';
import SEO from '../components/global/seo';

import './index.scss';

const HomePage = () => (
  <>
    <SEO title="Accueil"/>

    <HomeHeader/>

    <main className="home">

      <HomeCarousel/>

      <HomeMenuRow/>

      <HomeSolutions/>

      <HomeVision/>

      <HomeClients/>

      {/*<HomeContact/>*/}

      {/*<p className="TODO">Blog</p>*/}

    </main>

    <HomeFooter/>

  </>
);

export default HomePage;

