import * as React from 'react';
import LogoTextBaseline from '../global/logo-text-baseline';
import './home-header.scss';

const HomeHeader = () => (
  <header>
    <h1><LogoTextBaseline></LogoTextBaseline></h1>
  </header>
);

export default HomeHeader;

